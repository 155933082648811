import React, { useEffect, useState } from 'react';
import { fetchVehiclesAction } from '../../redux/actions/vehicleAction';
import { getImageURL } from '../../utils/imageUrl';
import LOGO from '../../assets/images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import VehicleCard from '../../components/VehicleCard';
import { SkeletonComp } from '../../shared/Skelton';

const Vehicles = ({ active, onHandleActive }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { vehicles } = useSelector((state) => state?.vehicle);

    useEffect(() => {
        dispatch(fetchVehiclesAction(setLoading));
    }, [dispatch]);

    const Skeleton = () => {
        return (
            <>
                <div className="dashboard">
                    <div className="dashboard__grid">
                        {/* Left Column */}
                        <div className="dashboard__section">
                            <>
                                <SkeletonComp width={10} height={2} />
                                <SkeletonComp width={30} height={10} />
                                <SkeletonComp width={30} height={10} />
                                <SkeletonComp width={30} height={10} />
                            </>
                        </div>
                        {/* Right Column */}
                        <div className="dashboard__section"></div>
                    </div>
                </div>
            </>
        );
    };
    return loading ? (
        <Skeleton />
    ) : (
        <div>
            {vehicles?.length > 0 ?
                vehicles?.map((item) => (
                    <VehicleCard
                        name={`${item?.vehicleBrand?.name} ${item?.vehicleModel?.name }`}
                        price={item?.isSubscribed ? item?.subscription?.amountPaid : null}
                        image={getImageURL(item.vehicleModel.image) ? getImageURL(item.vehicleModel.image) : LOGO}
                        id={item._id}
                        handleActive={() => onHandleActive(item)}
                        active={active}
                    />
                )):"No vehicle"}
        </div>
    );
};

export default Vehicles;
