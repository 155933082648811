import React from 'react';
import { SkeletonComp, SkeletonComponent } from '../../shared/Skelton';

const SkeltonDashboard = () => {
    return (
        <div className="dashboard">
            <div className="dashboard__grid">
                {/* Left Column */}
                <div className="dashboard__section">
                    <></>
                </div>

                {/* Right Column */}
                <div className="dashboard__section">
                    <div className="dashboard__header">
                        <SkeletonComp width={10} height={2} />

                        <button className="dashboard__download-btn">
                            <SkeletonComp width={10} height={2} />
                        </button>
                    </div>

                    {/* Current Plan */}

                    <div className="">
                        <SkeletonComp width={40} height={15} />
                    </div>

                    {/* Membership Plans */}
                    <div className="dashboard__section">
                        <SkeletonComp width={15} height={2} />
                        <div className="flex justify-content-between">
                            <SkeletonComponent times={1} size={12} />
                            <SkeletonComponent times={1} size={12} />
                            <SkeletonComponent times={1} size={12} />
                        </div>
                    </div>

                    {/* Billing Details */}
                    <div className="billing">
                        <SkeletonComp width={15} height={2} />
                        <SkeletonComp width={40} height={10} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeltonDashboard;
