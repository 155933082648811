import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';

import _ from 'lodash';
import { InputText } from 'primereact/inputtext';

export function CustomRadioButtons({
    label,
    name,
    onChange,
    data,
    value,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    options,
    optionLabel,
    ...props
}) {
    return (
        <div div className="">
            {options.map((item, i) => (
                <div key={i} className={`flex align-items-center mr-6 mb-3`}>
                    <RadioButton
                        value={item}
                        className="m-0"
                        onChange={(e) => onChange && onChange({ name, value: e.target.value, ...e })}
                        checked={_.isEqual(value || data?.[name], item)}
                        id={name}
                        name={item.name}
                        {...props}
                    />
                    &nbsp;&nbsp;
                    <label htmlFor={name}>{optionLabel ? item[optionLabel] : item?.name}</label>
                </div>
            ))}
            {errorMessage || data?.formErrors?.[name] ? <small className="p-error">{errorMessage || data?.formErrors?.[name]}</small> : null}
        </div>
    );
}

export const CustomInputNumber = ({ label, name, data, value, onChange, errorMessage, extraClassName, required, col, inputClass, ...props }) => {
    return (
        <InputNumber
            id={name}
            name={name}
            value={value || data?.[name]}
            onChange={(e) => onChange && onChange({ ...e, name: name, value: e.value })}
            className={`w-full ${inputClass ? inputClass : ''} ${errorMessage ? 'p-invalid' : ''}`}
            useGrouping={props.useGrouping || false}
            {...props}
        />
    );
};
export const CustomInputText = ({ label, name, data, value, onChange, errorMessage, extraClassName, required, col, inputClass, ...props }) => {
    return (
        <InputText
            id={name}
            name={name}
            value={value || data?.[name]}
            onChange={(e) => onChange && onChange({ ...e, name: name, value: e.target.value })}
            className={`w-full ${inputClass ? inputClass : ''} ${errorMessage ? 'p-invalid' : ''}`}
            {...props}
        />
    );
};
