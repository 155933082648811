import React from 'react';
import './index.css';
import placeholder from '../../assets/images/logo.png';

export default function VehicleCard({ name, price, image, id, handleActive, active }) {
    return (
        <div className={`vehicle-card relative p-5 cursor-pointer ${id === active ? 'vehicle-card--active' : 'vehicle-card--inactive'}`} onClick={handleActive}>
            <div className="vehicle-card__content">
                <img src={image} alt={name} className="vehicle-card__image" onError={(e) => (e.target.src = placeholder)} />
                <div>
                    <h3 className="vehicle-card__name font-semibold m-0 mb-2">{name}</h3>
                    <p className="vehicle-card__price m-0">{price ? `₹ ${price}` : null}</p>
                </div>
            </div>
        </div>
    );
}
