import { types } from '../types/types';
const intitalState = {
    vehicles: [],
};

const vehicleReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_VEHICLES:
            return {
                ...state,
                vehicles: action.payload,
            };
        default:
            return { ...state };
    }
};

export default vehicleReducer;
