import React, { useEffect, useState } from 'react';
import PlanCard from '../../components/PlanCard';
import { CreditCard, Download } from 'lucide-react';
import SkeltonDashboard from './SkeltonDashboard';
import { useDispatch } from 'react-redux';
import {
    afterSubscription,
    buySubscription,
    cancelSubscription,
    downloadInvoice,
    getVehiclePlan,
    upgradeSubscription,
} from '../../redux/actions/vehicleAction';
import { useRazorpay } from 'react-razorpay';
import _ from 'lodash';
import moment from 'moment';
import { showToast } from '../../redux/actions/toastAction';

const Plans = ({ active, loading, plans, setPlans }) => {
    const activePlans = plans?.activePlans;
    const dispatch = useDispatch();
    const { Razorpay } = useRazorpay();
    const [loading1, setLoading] = useState(false);

    const [showCount, setShowCount] = useState(3);

    const handleRazorPay = (id, item, type) => {
        const options = {
            key: 'rzp_test_Z0HPaahRvegg9P',
            amount: item.amount, // Amount in paise
            subscription_id: id,
            currency: 'INR',
            name: item.name,
            description: item.description,
            handler: (response) => {
                console.log(response, 'response');
                setTimeout(() => {
                    dispatch(
                        afterSubscription(response.razorpay_subscription_id, () => {
                            if (type == 'upgrade') {
                                dispatch(
                                    showToast({
                                        severity: 'success',
                                        summary:
                                            'The payment for the upgraded plan will be deducted from your account once the current plan ends. The charge will be applied now.',
                                    }),
                                );
                            }

                            dispatch(
                                getVehiclePlan(active, _, (res) => {
                                    setPlans(res);
                                }),
                            );
                        }),
                    );
                }, [5000]);
            },

            theme: {
                color: '#F58F28',
            },
        };

        const razorpayInstance = new Razorpay(options);
        razorpayInstance.open();
    };

    const handleUpgrade = (item) => {
        dispatch(upgradeSubscription(item._id, active, activePlans?.[0]?.userSubscription?._id, handleRazorPay, item, setLoading));
        console.log(item, 'item');
    };

    const handleBuy = (item) => {
        dispatch(buySubscription(item._id, active, handleRazorPay, item));
    };
    const [pdfUrl, setPdfUrl] = useState('');
    const handleDownload = async () => {
        const pdfUrl = await dispatch(downloadInvoice(activePlans?.[0]?.userSubscription?._id));
        setPdfUrl(pdfUrl);
    };

    useEffect(() => {
        if (pdfUrl) {
            window.open(pdfUrl, '_blank'); // Opens the PDF in a new tab for direct download
        }
    }, [pdfUrl]);
    console.log(pdfUrl);

    return loading ? (
        <SkeltonDashboard />
    ) : (
        <div className="md:border-left-1 px-2 md:px-6 border-300 h-full">
            {active && (
                <div className="dashboard__section">
                    <div className="dashboard__header align-items-start">
                        <h2 className="dashboard__title mt-0">My plan</h2>
                        {activePlans?.length > 0 && (
                            <>
                                <button className="p-0 underline dashboard__download-btn" onClick={handleDownload}>
                                    {/* <Download size={20} /> */}
                                    <span>Download Invoice</span>
                                </button>
                            </>
                        )}
                    </div>

                    {/* Current Plan */}
                    {activePlans?.length > 0 ? (
                        activePlans?.map((item) => (
                            <div className="current-plan mb-2">
                                <div className="current-plan__header">
                                    <div>
                                        <h3 className="current-plan__title mb-2">{item?.name}</h3>
                                        {activePlans?.[0]?.userSubscription?.status !== 'cancelled' && (
                                            <p className="current-plan__date m-0">Next Billing Date - 27/04/2024</p>
                                        )}
                                    </div>
                                    <p className="current-plan__price m-0">₹{item?.amount}</p>
                                </div>
                                <div className="p-3">
                                    <div className="mb-2">
                                        {item?.features?.slice(0, showCount)?.map((feature) => (
                                            <ul className="current-plan__features">
                                                <li className="current-plan__feature">{feature}</li>
                                            </ul>
                                        ))}
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                        <div
                                            className=" text-main underline cursor-pointer"
                                            onClick={() => (showCount === item.features?.length ? setShowCount(3) : setShowCount(item?.features?.length))}
                                        >
                                            {showCount === item.features?.length ? 'Show Less' : 'Show More'}
                                        </div>
                                        {item?.userSubscription?.status === 'cancelled' ? (
                                            <div className="text-red-500">Cancelled</div>
                                        ) : (
                                            <button
                                                className="current-plan__cancel text-right"
                                                onClick={() =>
                                                    dispatch(
                                                        cancelSubscription(item?.userSubscription?._id, () =>
                                                            dispatch(
                                                                getVehiclePlan(active, _, (res) => {
                                                                    setPlans(res);
                                                                }),
                                                            ),
                                                        ),
                                                    )
                                                }
                                            >
                                                <u>{'Cancel plan'}</u>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <>
                            <div className="font-medium text-center text-lg my-4 text-yellow-400">No Active Plan Found</div>
                        </>
                    )}
                    {plans?.lastExpiredPlan?.length > 0
                        ? plans?.lastExpiredPlan?.map((item) => (
                              <div className="current-plan mb-2">
                                  <div className="current-plan__header">
                                      <div>
                                          <h3 className="current-plan__title mb-2">{item?.name}</h3>
                                          {plans?.lastExpiredPlan?.[0]?.userSubscription?.status !== 'expired' && (
                                              <p className="current-plan__date m-0">Next Billing Date - 27/04/2024</p>
                                          )}
                                      </div>
                                      <p className="current-plan__price m-0">₹{item?.amount}</p>
                                  </div>
                                  <div className="p-3">
                                      <div className="mb-2">
                                          {item?.features?.slice(0, showCount)?.map((feature) => (
                                              <ul className="current-plan__features">
                                                  <li className="current-plan__feature">{feature}</li>
                                              </ul>
                                          ))}
                                      </div>
                                      <div className="flex justify-content-between align-items-center">
                                          <div
                                              className="  text-main underline cursor-pointer"
                                              onClick={() => (showCount === item.features?.length ? setShowCount(3) : setShowCount(item?.features?.length))}
                                          >
                                              {showCount === item.features?.length ? 'Show Less' : 'Show More'}
                                          </div>

                                          {item?.userSubscription?.status === 'expired' && (
                                              <button className="current-plan__cancel text-right" onClick={() => handleBuy(plans?.lastExpiredPlan?.[0])}>
                                                  <u>{'Renew plan'}</u>
                                              </button>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          ))
                        : null}

                    <div className="text-sm  my-2 text-red-400">
                        {activePlans?.[0]?.userSubscription?.status === 'expired'
                            ? `Note:  ${activePlans?.[0]?.name} has been expired on
                        ${moment(activePlans?.[0]?.userSubscription?.currentEnd).format('DD-MM-YYYY')}`
                            : activePlans?.[0]?.userSubscription?.status === 'cancelled'
                            ? `Note:  ${activePlans?.[0]?.name} has been cancelled and will be active till
                        ${moment(activePlans?.[0]?.userSubscription?.currentEnd).format('DD-MM-YYYY')}`
                            : ''}
                    </div>

                    {/* Upcoming Plans */}
                    {plans?.upcomingPlans?.length > 0 && (
                        <div className="dashboard__section">
                            <h3 className="dashboard__title">Upcoming plans</h3>
                            <div style={{ display: 'grid' }}>
                                {plans?.upcomingPlans?.map((item) => (
                                    <div className="col-6">
                                        <div className="plan-card ">
                                            <h3 className="plan-card__title">{item.name}</h3>
                                            <p className="plan-card__price">₹{item.amount}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Membership Plans */}
                    <div className="dashboard__section">
                        <h3 className="dashboard__title">Membership plans</h3>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                            {plans?.availablePlans?.length > 0 ? (
                                plans?.availablePlans?.map((item) => (
                                    <PlanCard
                                        title={item?.name}
                                        price={item.amount}
                                        handleUpgrade={() => handleUpgrade(item)}
                                        plans={plans?.activePlans?.length}
                                        handleBuy={() => handleBuy(item)}
                                        loading={loading1}
                                    />
                                ))
                            ) : (
                                <>
                                    <div className="font-medium text-center text-lg my-4 text-yellow-400">No Plan Found</div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* Billing Details */}
                    {activePlans?.length > 0 && (
                        <div className="billing">
                            <h3 className="billing__title">Billing Details</h3>
                            <div className="billing__card">
                                <div className="billing__header">
                                    <div>
                                        <p className="billing__plan-name text-white mt-0 mb-2">{activePlans?.[0]?.name}</p>
                                        <p className="billing__date m-0 text-white">
                                            {moment(activePlans?.[0]?.userSubscription?.currentEnd).format('dddd,MM/DD/YYYY')}
                                        </p>
                                    </div>
                                    <p className="billing__price m-0">₹{activePlans?.[0]?.amount}</p>
                                </div>
                                <div className="billing__payment text-white">
                                    <CreditCard />
                                    {activePlans?.[0]?.userSubscription?.paymentMethod === 'card' ? (
                                        <span className="billing__card-number">{activePlans?.[0]?.userSubscription?.cardDetails?.number}</span>
                                    ) : activePlans?.[0]?.userSubscription?.paymentMethod === 'upi' ? (
                                        <span>{activePlans?.[0]?.userSubscription?.vpa}</span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Plans;
