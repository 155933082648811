import { HashRouter, Route, Routes } from 'react-router-dom';
import './styles.scss';
import Home from './views/home';
import Login from './views/login';
import { LandingPage } from './views/landing-page';
import TermsCondition from './views/others-pages/Terms&Condition';
import PrivacyPolicy from './views/others-pages/PrivacyPolicy';
import RefundPolicy from './views/others-pages/RefundPolicy';
import { ProtectedRoutes } from './routes.js/protectedRoutes';
import Loader from './shared/Loader';
import TokenLogin from './views/TokenLogin';

const App = () => {
    return (
        <>
            <Loader />
            <div className="App">
                <HashRouter>
                    <Routes>
                        {/* Public Routes */}
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/verification" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/redirect/:token/:vehicleID" element={<TokenLogin />} />

                        <Route path="/terms-and-condition" element={<TermsCondition />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/refund-policy" element={<RefundPolicy />} />

                        {/* Protected Routes */}
                        <Route path="/*" element={<ProtectedRoutes />} />
                    </Routes>
                </HashRouter>
            </div>
        </>
    );
};

export default App;
