import { combineReducers } from 'redux';
import loaderReducer from './loaderReducer';
import toastReducer from './toastReducer';
import vehicleReducer from './vehicleReducer.js';
import profileReducer from './profileReducer.js';

export default combineReducers({
    toast: toastReducer,
    loader: loaderReducer,
    vehicle: vehicleReducer,
    user: profileReducer,
});
