import api from '../../services/api';
import Constants from '../../services/constants';
import { types } from '../types/types';
import { showToast } from './toastAction';

export const fetchVehiclesAction = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api('get', Constants.END_POINT.GET_VEHICLES);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_VEHICLES,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    if (setLoading) {
        setLoading(false);
    }
};

export const getVehiclePlan = (id, setLoading, returnData) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api('get', Constants.END_POINT.VEHICLE_PLAN, {}, { vehicleId: id });
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    if (setLoading) {
        setLoading(false);
    }
};

export const downloadInvoice = (id) => async (dispatch) => {
    const res = await api('get', Constants.END_POINT.DOWNLOAD_INVOICE + id);
    if (res.success) {
        if (res.data) {
            return res.data;
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
};
export const cancelSubscription = (subscriptionId, next) => async (dispatch) => {
    const res = await api('post', Constants.END_POINT.CANCEL_SUBSCRIPTION, { subscriptionId });
    if (res.success) {
        if (res.data) {
            next();
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
};
export const upgradeSubscription = (planId, vehicleId, currentSubscriptionId, handleRazorpaySubscription, item, setLoading) => async (dispatch) => {
    setLoading(true);

    const res = await api('post', Constants.END_POINT.UPGRADE_SUBSCRIPTION, {
        planId,
        vehicleId,
        currentSubscriptionId,
    });
    if (res.success) {
        if (res.data) {
            handleRazorpaySubscription(res.data.id, item, 'upgrade');
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};
export const afterSubscription = (subscriptionId, next) => async (dispatch) => {
    const res = await api('post', Constants.END_POINT.AFTER_SUBSCRIPTION, {
        subscriptionId,
    });
    if (res.success) {
        if (res.data) {
            next();
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
};
export const buySubscription = (planId, vehicleId, handleRazorPay, item) => async (dispatch) => {
    const res = await api('post', Constants.END_POINT.BUY_SUBSCRIPTION, {
        planId,
        vehicleId,
    });
    console.log(res, 'res');
    if (res.success) {
        if (res.data) {
            handleRazorPay(res.data.id, item, 'buy');
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
};
