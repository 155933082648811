import React, { useState, useEffect } from 'react';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { getVehiclePlan } from '../../redux/actions/vehicleAction';
import Vehicles from './Vehicles';
import Plans from './Plans';
import { useNavigate, useParams } from 'react-router-dom';

export default function Dashboard() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { vehicles } = useSelector((state) => state?.vehicle);
    const [active, setActive] = useState(null);
    const [plans, setPlans] = useState(null);
    const navigate = useNavigate();
    const { vehicleId } = useParams();

    console.log(vehicleId, 'vehicleId');
    const navigateAndFetchPlans = (id) => {
        if (id) {
            navigate(`/dashboard/${id}`, { replace: true });
            setActive(id);
            dispatch(
                getVehiclePlan(id, setLoading, (res) => {
                    setPlans(res);
                }),
            );
        }
    };
    useEffect(() => {
        console.log(typeof vehicleId);
        const idToFetch = vehicleId !== 'null' ? vehicleId : vehicles?.[0]?._id; // Use vehicleId if available; otherwise, use the first vehicle's ID
        if (idToFetch) {
            navigateAndFetchPlans(idToFetch);
        }
    }, [vehicleId, vehicles]);

    const handleActive = (item) => {
        navigate(`/dashboard/${item._id}`, { replace: true });
        setActive(item._id);
        dispatch(
            getVehiclePlan(item._id, setLoading, (res) => {
                setPlans(res);
            }),
        );
    };

    console.log('plans>>', plans);

    return (
        <div className="dashboard">
            <div className="grid ">
                {/* Left Column */}
                <div className="md:col-5 p-2 md:px-5">
                    <h2 className="dashboard__title mt-0 left__dashboard_title_section">My vehicles</h2>
                    <div className="w-full">
                        <Vehicles active={active} onHandleActive={handleActive} />
                    </div>
                </div>
                {/* Right Column */}
                <div className="md:col-7">
                    <Plans active={active} loading={loading} plans={plans} setPlans={setPlans} />
                </div>
            </div>
        </div>
    );
}
