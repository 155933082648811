import { HOST } from '../constants';
import LOGO from '../assets/images/logo.png';

export const getImageURL = (path) => {
    console.log(HOST + path);
    if (path) {
        if (typeof path === 'string') {
            if (path.includes('http')) {
                return path;
            } else {
                return HOST + path;
            }
        } else {
            return URL.createObjectURL(path);
        }
    } else {
        return LOGO;
    }
};
