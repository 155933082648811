import React from 'react';
import './index.css';
import PrimaryButton from '../../shared/Button/PrimaryButton';

export default function PlanCard({ title, price, handleUpgrade, plans, handleBuy, loading }) {
    console.log(plans);
    return (
        <div className="plan-card">
            {/* {save && (
                <div className="plan-card__save-badge">
                    Save 5%
                </div>
            )} */}
            <h3 className="plan-card__title">{title}</h3>
            <p className="plan-card__price">₹{price}</p>
            <PrimaryButton className="plan-card__button border-round-lg text-center" onClick={plans === 0 ? handleBuy : handleUpgrade} loading={loading}>
                {plans === 0 ? 'Buy' : 'Upgrade'}
            </PrimaryButton>
        </div>
    );
}
